import Vue from 'vue'
import App from './App.vue'

import router from './router'

import 'amfe-flexible'

import 'normalize.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.config.productionTip = false

import {Backtop, Row, Col, Image} from 'element-ui'

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

import 'element-ui/lib/theme-chalk/index.css'

import 'element-ui/lib/theme-chalk/display.css'

Vue.use(Backtop)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)

Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
