import Vue from 'vue'
import Router from 'vue-router'


const constantRouterMap = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home')
    }
]

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: constantRouterMap
})
